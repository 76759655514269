import { computed } from 'vue'
import api from '@/services/api.ts'
import { useQuery } from '@tanstack/vue-query'
import { useClaimCustom } from '@/composables/contracts/claim-custom.ts'
import { apiToken, walletConnected } from '@/composables/auth.ts'

export const useSnapshot = () => {
  const { totalCustomClaimableBalance, totalCustomClaimedBalance } = useClaimCustom()

  const { data: snapshotData, refetch: refetchSnapshot, isLoading: loadingSnapshot } = useQuery({
    queryKey: ['snapshot', walletConnected],
    queryFn: async () => {
      return (await api.getSnapshotV2()).snapshot
    },
    enabled: !!apiToken.value,
    initialData: null,
  })
  const totalTokenAllocation = computed(() => (snapshotData.value?.totalTokenAllocation || 0) + totalCustomClaimableBalance.value + totalCustomClaimedBalance.value)
  const allocationTypes = computed(() => snapshotData.value?.allocationTypes || null)

  return {
    snap: snapshotData,
    loadingSnapshot,
    refetchSnapshot,
    totalTokenAllocation,
    allocationTypes,
  }
}
