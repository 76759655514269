import { computed } from 'vue'
import api from '@/services/api.ts'
import { useQuery } from '@tanstack/vue-query'
import { apiToken, walletConnected } from '@/composables/auth.ts'

export const useStakes = () => {

  const { data: myStakes, refetch: refetchMyStakes, isLoading: loadingMyStakes } = useQuery({
    queryKey: ['myStakes', walletConnected],
    queryFn: async () => {
      return await api.getStakes()
    },
    enabled: !!apiToken.value,
    initialData: null,
  })

  const hasStakes = computed(() => {
    return (myStakes.value && myStakes.value.stakes.length > 0) ?? null
  })
  const myBoost = computed(() => {
    return myStakes.value?.powerConfig.manualBoost
  })
  const oneLingoBaseDailyPower = computed(() => {
    if (!myStakes.value) return 0
    return myStakes.value.powerConfig.oneLingoBaseDailyPower ?? 0
  })

  return {
    myStakes,
    refetchMyStakes,
    loadingMyStakes,
    hasStakes,
    myBoost,
    oneLingoBaseDailyPower,
  }
}
