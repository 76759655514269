import { apiToken } from '@/composables/auth'
import { callApi } from '@/services/call-api'
import { Beneficiary } from '@/types/beneficiary.ts'
import { Snapshot } from '@/types/snapshot.ts'
import { LOCK_DURATION_ID } from '@/types/staking.ts'

export enum RAFFLE_PRIZE_TYPE {
  VOUCHERS = 'vouchers',
  PHYSICAL = 'physical',
  NONE = 'none',
}

export enum RAFFLE_TAG {
  HIGHLIGHTED = 'highlighted',
}

export type RaffleWonPrize =  { type: RAFFLE_PRIZE_TYPE.PHYSICAL, name: string } | { type: RAFFLE_PRIZE_TYPE.VOUCHERS, code: string, amount: number } | { type: RAFFLE_PRIZE_TYPE.NONE }

export type Raffle = {
  id: string
  title: string
  startDate: string
  endDate: string
  ticketPrice: number
  maxTicketsPerUser: number | null
  prizes: {
    name: string
    description: string
    imageUrl: string
    count: number
    type: RAFFLE_PRIZE_TYPE
  }[]
  metadata: {
    ticketsCount: number
    isConcluded: boolean
    tags: RAFFLE_TAG
    participationQuestions: { question: string }[]
  }
}

export type RaffleStatus = {
  ticketsCount: number
  wonPrize: RaffleWonPrize | null
  submission: { email: string } | null
  prizeSubmittedData: Record<string, string> | null
  raffleSubmittedData: Record<string, string> | null
}

export type Stake = {
  id: string
  amount: string
  lingoPrice: string
  timestamp: number
  lockDurationId: LOCK_DURATION_ID
  unlockBlock: number
  boost: number
  dailyPower: number
  baseDailyPower: number
}

export class ApiError extends Error {
  constructor(message: string, public readonly metadata?: Record<string, unknown>) {
    super(message)
  }
}

export class Api {
  private async fetch<T>(input: {
    path: string,
    body?: object,
    method: 'POST' | 'DELETE' | 'GET'
  }): Promise<T> {
    return await callApi({
      ...input,
      path: `/api${input.path}`,
      headers: {
        'Authorization': `Bearer ${apiToken.value}`,
      },
    })
  }

  public async getMerkleProof(beneficiary: Beneficiary) {
    return await this.fetch<{ proof: string[] }>({
      path: '/staking/merkle-proof',
      method: 'POST',
      body: {
        beneficiary,
      },
    })
  }

  public async getStakes() {
    return await this.fetch<{
      stakes: Stake[],
      powers: { total: string } & Record<string, string>
      powerConfig: {
        oneLingoBaseDailyPower: number
        manualBoost: { amount: number, expiresAt: string } | null
      }
    }>({
      path: '/staking/stakes',
      method: 'POST',
    })
  }

  public async getPickedXmasVoucher() {
    return await this.fetch<{ voucher: { amount: number, code: string } | null }>({
      path: '/rewards/xmas/voucher',
      method: 'GET',
    })
  }

  public async pickXmasVoucher() {
    return await this.fetch<{ voucher: { amount: number, code: string } | null }>({
      path: '/rewards/xmas/pick-voucher',
      method: 'POST',
    })
  }

  public async checkRaffleWonPrize({ raffleId }: { raffleId: string }) {
    return await this.fetch<{ prize: RaffleWonPrize | null }>({
      path: `/raffles/${raffleId}/check-my-prize`,
      method: 'POST',
    })
  }

  public async submitRafflePrizeData({ raffleId, email }: { raffleId: string, email: string }) {
    return await this.fetch<{ prize: RaffleWonPrize | null }>({
      path: `/raffles/${raffleId}/submit-prize-data`,
      method: 'POST',
      body: { email },
    })
  }

  public async getUserStatusForRaffles() {
    return await this.fetch<{
      me: Record<string, RaffleStatus>
    }>({
      path: `/raffles/me`,
      method: 'GET',
    })
  }

  public async getMe() {
    return await this.fetch<{
      me: { referralCode: string }
    }>({
      path: `/users/me`,
      method: 'GET',
    })
  }

  public async getRaffles() {
    return await this.fetch<{ raffles: Raffle[] }>({
      path: `/raffles`,
      method: 'GET',
    })
  }

  public async buyRaffleTickets({ raffleId, count, userData }: { raffleId: string, count: number, userData?: Record<string, string> }) {
    return await this.fetch<{ success: true, newTotalPower: string } | { success: false, error: string }>({
      path: `/raffles/${raffleId}/buy-tickets`,
      method: 'POST',
      body: { count, userData },
    })
  }

  public async isEligibleForXmasVoucher() {
    return await this.fetch<{ isEligible: boolean }>({
      path: '/rewards/xmas/voucher/is-eligible',
      method: 'GET',
    })
  }

  public async getLingoPrice() {
    return await this.fetch<{ price: number }>({
      path: '/coin/price',
      method: 'GET',
    })
  }

  public async getConfig() {
    return await this.fetch<{
      tiers: Record<string, number>
      airdropWalletsCount: number
      lockDurations: Record<LOCK_DURATION_ID, { lockDurationBlocks: number, boost: number, index: number }>
      power: {
        base: number
        decimalCount: number
      } }>({
        path: '/staking/config',
        method: 'GET',
      })
  }

  public async getSnapshotV2() {
    return await this.fetch<{ snapshot: Snapshot | null }>({
      path: '/staking/snapshot',
      method: 'GET',
    })
  }

  public async submitKucoinId(kucoinId: string) {
    return await this.fetch<{ success: boolean, message: string }>({
      path: '/open-form/kucoin',
      method: 'POST',
      body: {
        kucoinId,
      },
    })
  }

  public async getQuote(amount: number, currency: string, convertedCurrency: string, blockchain: string, quoteCurrency: string) {
    return await this.fetch<{ data: { payment_method: string, converted_amount: string }[] }>({
      path: '/ramp/buy/quote',
      method: 'POST',
      body: {
        amount,
        currency,
        converted_currency: convertedCurrency,
        blockchain,
        quote_currency: quoteCurrency,
      },
    })
  }

  public async checkTransaction(txHash: string) {
    await this.fetch<{ success: boolean }>({
      path: '/staking/check-tx',
      method: 'POST',
      body: {
        txHash,
      },
    })
  }

  public async getIslandCampaignSignature() {
    return await this.fetch<{ signature: `0x${string}` | null, amount: number }>({
      path: '/token/launch-island-campaign/signature',
      method: 'POST',
    })
  }

  public async getIslandCampaignAmount() {
    return await this.fetch<{ amounts: { firstClass: number, dream: number } }>({
      path: '/token/launch-island-campaign/tokens',
      method: 'GET',
    })
  }
}

export default new Api()
