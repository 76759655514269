import { createApp } from 'vue'
import './index.css'
import './style.css'
import App from './App.vue'
import router from './router'
import * as Sentry from '@sentry/vue'
import { appEnv, env } from '@/env.ts'
import mixpanel from 'mixpanel-browser'
import vSelect from 'vue-select'
import { VueQueryPlugin } from '@tanstack/vue-query'

const app = createApp(App)

if (env.sentryDsn) {
  Sentry.init({
    app,
    dsn: env.sentryDsn,
    maxValueLength: 4096,
    allowUrls: [new RegExp('^https://app.lingocoin.io/'), new RegExp('^https://lingo-staking-webapp-dev.pages.dev/')],
    integrations: [
      Sentry.browserTracingIntegration({ router }),
      Sentry.replayIntegration(),
    ],
    // Tracing
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [/^https:\/\/api-(dev|prod).lingo.tropee.com/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    environment: appEnv,
  })
}
if (env.mixpanelToken) {
  mixpanel.init(env.mixpanelToken, { persistence: 'localStorage' })
} else {
  mixpanel.init('test', { test: true })
}
app.use(router)
app.use(VueQueryPlugin)
app.component('VSelect', vSelect)

app.use(VueQueryPlugin, {
  enableDevtoolsV6Plugin: true,
})

app.mount('#app')
