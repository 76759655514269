import { LOCK_DURATION_ID } from '@/types/staking.ts'
import { onMounted, ref } from 'vue'
import api from '@/services/api.ts'
import { getBlockNumber } from '@wagmi/core'
import { config } from '@/composables/wallet/wallet-init.ts'

export const lockConfig = ref<{
  airdropWalletsCount: number
  lockDurations: Record<LOCK_DURATION_ID, { lockDurationBlocks: number, boost: number, index: number }>
  power: {
    base: number
    decimalCount: number
  } } | null>(null)

export const usePowerContract = () => {

  const blockNumber = ref<BigInt>(BigInt('0'))

  const fetchBlockNumber = async () => {
    blockNumber.value = await getBlockNumber(config)
  }

  async function getLockConfig() {
    try {
      lockConfig.value = await api.getConfig()
    } catch (error) {
      console.error('Error fetching lock config:', error)
    }
  }

  function calculatePowerPerDay(coins: number, lockDuration: LOCK_DURATION_ID) {
    if (!lockConfig.value) {
      throw new Error('Lock config not available')
    }
    const base = lockConfig.value.power.base
    const decimalCount = lockConfig.value.power.decimalCount

    const lockData = lockConfig.value.lockDurations[lockDuration]
    if (!lockData) {
      throw new Error(`Invalid lock duration: ${lockDuration}`)
    }
    const boost = lockData.boost
    return (coins * base * (1 + boost) * 24) / Math.pow(10, decimalCount)
  }

  onMounted(async () => {
    await fetchBlockNumber()
  })

  return {
    calculatePowerPerDay,
    getLockConfig,
    blockNumber,
  }
}