<template>
  <div class="min-h-svh flex flex-col items-center justify-between">
    <VueQueryDevtools />
    <router-view v-slot="{ Component }">
      <component
        v-if="isHome"
        :is="Component"
        :key="route.path"
      />
      <BaseLayout v-else>
        <transition
          name="fade"
          mode="out-in"
        >
          <component
            :is="Component"
            :key="route.path"
          />
        </transition>
      </BaseLayout>
    </router-view>
  </div>
</template>

<script setup lang="ts">
import { RouterView, useRoute, useRouter } from 'vue-router'
import { init } from './composables/wallet/wallet-init.ts'
import { computed, onMounted, watch } from 'vue'
import BaseLayout from '@/components/layout/BaseLayout.vue'
import { apiToken, hasTokenExpired } from '@/composables/auth.ts'
import { LingoRouteName } from '@/router/routes.ts'
import { useWalletAuth } from '@/composables/wallet/use-wallet-auth.ts'
import { VueQueryDevtools } from '@tanstack/vue-query-devtools'

const route = useRoute()
const router = useRouter()
const isHome = computed(() => route.name === 'home')
init()
// const { refetchSnapshot } = useSnapshot()
watch(apiToken, () => {
  if (route.meta.requiresAuth && !apiToken.value) {
    router.push({ name: LingoRouteName.CONNECT_WALLET_V2, query: { redirect: route.fullPath } })
  }
}, { immediate: true })

const { logout } = useWalletAuth()

onMounted(async () => {
  if (apiToken.value && hasTokenExpired()) {
    await logout()
  }
})
</script>

<style>
.logo {
    height: 6em;
    padding: 1.5em;
    will-change: filter;
    transition: filter 300ms;
}
.logo:hover {
    filter: drop-shadow(0 0 2em #646cffaa);
}
.logo.vue:hover {
    filter: drop-shadow(0 0 2em #42b883aa);
}

.fade-enter-active, .fade-leave-active {
    transition: opacity 0.2s;
}
.fade-enter-from, .fade-leave-to {
    opacity: 0;
}
</style>
