const baseContracts: Record<string, { stakingAddress: `0x${string}`, vestingAddress: `0x${string}`, tokenAddress: `0x${string}`, lingoTokenAddress: `0x${string}` }> = {
  dev: {
    stakingAddress: '0x3827590BF09F0040B2462bf1C6A42142Df1828Ae',
    vestingAddress: '0x13BB4a1F1546c594Beb4C78f262ceB1e1b889068',
    tokenAddress: '0xd72e9dac2d304377060357c284e33399417abf57',
    lingoTokenAddress: '0x589E4b06C2eaeA6A1B172b519C5298F9AC06EeD1',
  },
  prod: {
    stakingAddress: '0x9aF8C0dac726CcEE2BFd6c0f3E21f320d42398AC',
    vestingAddress: '0xAd11F733E401E16C72033c5DECAf05dcC0e1BEB8',
    tokenAddress: '0xfb42Da273158B0F642F59F2Ba7cc1d5457481677',
    lingoTokenAddress: '0x610111763A4a6C64DD8926c12CA3e52Fb7b7897c',
  },
}
export const appEnv = import.meta.env.VITE_APP_ENV as string
export const env = {
  app: {
    url: import.meta.env.VITE_APP_URL as string,
    isProd: appEnv === 'prod',
  },
  api: {
    baseUrl: import.meta.env.VITE_API_BASE_URL as string,
  },
  contracts: {
    base: baseContracts[appEnv],
    rpcEndpoint: import.meta.env.VITE_RPC_ENDPOINT as string,
  },
  sentryDsn: import.meta.env.VITE_SENTRY_DSN as string,
  mixpanelToken: import.meta.env.VITE_MIXPANEL_TOKEN as string,
  posthogToken: import.meta.env.VITE_POSTHOG_API_KEY as string,
}
