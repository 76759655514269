<template>
  <div class="w-full">
    <BaseButton
      class="w-full"
      :style-type="buttonStyle"
      @click="loginHandle()"
    >
      Connect wallet
    </BaseButton>
  </div>
</template>
<script setup lang="ts">
import { useWalletAuth } from '../composables/wallet/use-wallet-auth.ts'
import BaseButton from '@/components/base/BaseButton.vue'

const { login } = useWalletAuth()
withDefaults(defineProps<{
  buttonStyle?: 'primary'
      | 'secondary'
      | 'tertiary'
      | 'transparent'
}>(), {
  buttonStyle: 'tertiary',
})

const emit = defineEmits<{
    (e: 'connectClick'): void
}>()

const loginHandle = async () => {
  emit('connectClick')
  await login()
}
</script>

<style scoped>

</style>
