
export enum LingoRouteName {
    DASHBOARD = 'Dashboard',
    CLAIMING_DASHBOARD = 'ClaimingDashboard',
    CLAIMING = 'Claiming',
    STAKING_DASHBOARD = 'StakingDashboard',
    STAKING = 'Staking',
    STAKING_RENEW = 'StakingRenew',
    START_EARNING = 'StartEarning',
    CONNECT_WALLET_V2 = 'ConnectWalletV2',
    CONNECT_WALLET = 'ConnectWallet',
    BUY = 'Buy',
    TERMS_AND_CONDITIONS = 'TermsAndConditions',
    PRIVACY_POLICY = 'PrivacyPolicy',
    REWARDS = 'Rewards',
    ADMIN_TOOLS = 'AdminTools',
}

export const lingoRoutePath: Record<LingoRouteName, string> = {
  [LingoRouteName.DASHBOARD]: '/dashboard',
  [LingoRouteName.CLAIMING_DASHBOARD]: `/claiming-dashboard`,
  [LingoRouteName.CLAIMING]: `/claiming-dashboard/claim`,
  [LingoRouteName.STAKING_DASHBOARD]: `/staking-dashboard`,
  [LingoRouteName.STAKING]: `/staking-dashboard/stake`,
  [LingoRouteName.STAKING_RENEW]: `/staking-dashboard/stake-renew/:id`,
  [LingoRouteName.START_EARNING]: `/start-earning`,
  [LingoRouteName.CONNECT_WALLET_V2]: `/connect`,
  [LingoRouteName.CONNECT_WALLET]: '/connect-wallet',
  [LingoRouteName.BUY]: `/buy`,
  [LingoRouteName.TERMS_AND_CONDITIONS]: `/terms-and-conditions`,
  [LingoRouteName.PRIVACY_POLICY]: `/privacy-policy`,
  [LingoRouteName.REWARDS]: `/rewards`,
  [LingoRouteName.ADMIN_TOOLS]: `/admin/tools`,
}
